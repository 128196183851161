<template>
    <div class="main-wrapper">
        <div class="class-top">
            <div class="top-left">
                <span class="left-title">班级管理</span>
            </div>
            <div class="top-right">
                <el-button type="primary" @click="addClass()">创建班级</el-button>
            </div>
        </div>
        <div class="major-screening">
            <span class="major-screening-title">专业筛选:</span>
            <el-select v-model="majorValue" placeholder="请选择" @change="classBy($event)">
                <el-option
                        v-for="item in optionsCreenin"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
        </div>
        <el-table :data="classTable" style="width: 100%; flex: 1" height="1%" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',}" :cell-style="{fontSize: '12px',color: '#333'}">
            <el-table-column prop="name" label="班级名称" align="left"></el-table-column>
            <el-table-column prop="major_name" label="专业名称" align="center"></el-table-column>
            <el-table-column prop="student_count" label="班级人数" align="center"></el-table-column>
            <el-table-column prop="teacher_count" label="教师人数" align="center"></el-table-column>
            <el-table-column label="操作" align="center" width="260">
                <template slot-scope="scope">
                    <el-button size="small" @click="assignTeachers(scope.row)">分配老师</el-button>
                    <el-button size="small" @click="editClass(scope.row)">编辑</el-button>
                    <el-button size="small" @click="deleteClass(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="classPages.currentPageNum"
                :page-size="classPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="classPages.total"
                @current-change="classCurrentChange">
        </el-pagination>
        <!--创建-编辑班级-->
        <el-dialog :title="classTitleType" :visible.sync="dialogAddClass" center width="30%" @close="resetForm()"
                   @opened="openAddClass" :close-on-click-modal="false">
            <el-form :model="addClassForm" ref="addClassForm" :rules="rules" label-position="left" class="student-info">
                <el-form-item label="班级名称" :label-width="formLabelWidth" prop="name">
                    <el-input v-model="addClassForm.name" ref="classInput" autocomplete="off" placeholder="请输入名称" style="width: 80%;"></el-input>
                </el-form-item>
                <el-form-item label="专业选择" :label-width="formLabelWidth" prop="major_id">
                    <el-select v-model="addClassForm.major_name" placeholder="请选择专业" @change="majorSelect($event)" style="width: 80%;">
                        <el-option
                                v-for="item in optionsCreenin"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                                v-show="item.id>0">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="medium" type="primary" @click="addToForm('addClassForm')">确 定</el-button>
                <el-button size="medium" @click="dialogAddClass = false">取 消</el-button>
            </div>
        </el-dialog>
        <!--创建-编辑班级-->
        <!--分配老师-->
        <AssignTeacher ref="AssignTeacherDialog" :classId="classId" @getClassList="getClassList"></AssignTeacher>
        <!--分配老师-->
    </div>
</template>

<script>
    import AssignTeacher from '../../components/../views/school/AssignTeacher.vue'
    export default {
        name: "ClassManage",
        components: {
            AssignTeacher,
        },
        data(){
            return{
                classTable:[],
                majorValue:'全部',
                optionsCreenin:[],
                classTitleType:'',
                dialogAddClass:false,
                distinguishBtn:'',
                formLabelWidth: '80px',
                addClassForm: {
                    name: '',
                    major_name:'',
                    major_id:'',
                },
                rules: {
                    name: [
                        {required: true, message: '请输入班级名称', trigger: 'blur'},
                        {min: 1, max: 12, message: '长度在 1 到12 个字符', trigger: 'blur'},
                    ],
                    major_id: [
                        {required: true, message: '请选择专业', trigger: 'change'},
                    ],
                },
                classId:0,
                //分页
                classPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        created() {
            this.getClassList();
            this.getMajorList();
        },
        methods:{
            // 获取专业列表专业筛选
            getMajorList(){
                this.$http.axiosGet(this.$api.getMajor,(res) => {
                    if (res.code === 200) {
                        this.optionsCreenin = res.data.data;
                        let ob = {
                            id: 0,
                            name: "全部"
                        };
                        this.optionsCreenin.unshift(ob);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 获取班级列表信息
            getClassList(major_id){
                let param ={
                    page: this.classPages.currentPageNum,
                    pageSize: this.classPages.eachPageNum,
                    paging: 1,

                };
                if(major_id){
                    param.major_id = major_id
                }
                // 获取班级信息
                this.$http.axiosGetBy(this.$api.getClass, param, res => {
                    if (res.code === 200) {
                        console.log(res.data.data);
                        this.classTable = res.data.data;
                        this.classPages.total = res.data.total;
                    }
                },
                err => {
                    console.log(err);
                }
            );
            },
            // 专业选择
            majorSelect(e) {
                this.addClassForm.major_id = e;
            },
            classBy(e) {
                this.major_id = e;
                this.getClassList(this.major_id);
            },
            // 创建班级
            addClass(){
                this.dialogAddClass = !this.dialogAddClass;
                this.classTitleType = '创建班级';
                this.distinguishBtn = 'add';
            },
            // 编辑班级
            editClass(row){
                this.dialogAddClass = !this.dialogAddClass;
                this.classTitleType = '编辑班级';
                this.distinguishBtn = 'edit';
                this.addClassForm.id = row.id;
                this.addClassForm.name = row.name;
                this.addClassForm.major_id = row.major_id;
                this.addClassForm.major_name = row.major_name;
            },
            // 保存班级
            addToForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let param = {
                            name: this.addClassForm.name,
                            major_id: this.addClassForm.major_id
                        };
                        this.$http.axiosGetBy(this.$api.className,param, res =>{
                            if(res.code === 200) {
                                let formData = new FormData();
                                formData.append("name",this.addClassForm.name);
                                formData.append("major_id",this.addClassForm.major_id);
                                if (this.distinguishBtn === 'edit'){
                                    formData.append("id",this.addClassForm.id);
                                }
                                this.$http.axiosPost(this.$api.getClass,formData, res => {
                                    if (res.code === 200) {
                                        this.$message({
                                            type:"success",
                                            message:res.message,
                                            duration:1000,
                                            onClose:()=>{
                                                this.dialogAddClass = !this.dialogAddClass;
                                                this.resetForm();
                                                this.getClassList();
                                            }
                                        });
                                    } else {
                                        this.$message({
                                            type:"error",
                                            message:res.message,
                                            duration:1000
                                        });
                                    }
                                }, err => {
                                    console.log(err);
                                });
                            }else {
                                this.$message({
                                    type:"error",
                                    message:res.message,
                                    duration:1000
                                });
                            }
                        });
                    }
                });
            },
            // 删除
            deleteClass(row){
                // console.log(row);
                if(row.teacher_count === 0){
                    this.$confirm("是否删除该数据，删除完将无法恢复", "提示消息", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                        center: true
                    }).then(()=> { this.$http.axiosPost(this.$api.deleteClass, {id:row.id}, res =>{
                        if(res.code === 200){
                            this.$message.success(res.message);
                            this.getClassList(this.major_id);
                        } else {
                            this.$message.warning(res.message);
                        }
                    },
                     err => { console.log(err);
                    });
                    }).catch(()=>{
                       this.$message.info("已取消删除");
                    });
                } else {
                    this.$confirm("暂时无法删除，请先删除班级学生", "提示消息", {
                        confirmButtonText: "确定",
                        type: "warning",
                        center: true
                    });
                }
            },
            assignTeachers(row){
                this.classId = row.id;
                this.$refs.AssignTeacherDialog.AssignTeacher = true;
            },
            //重置表单
            resetForm() {
                this.$refs.addClassForm.resetFields();
                for (let key in this.addClassForm) {
                    this.addClassForm[key] = "";
                }
            },
            //打开弹窗聚焦输入框
            openAddClass() {
                this.$refs.classInput.focus();
            },
            //分页
            classCurrentChange(val) {
                this.classPages.currentPageNum = val;
                this.getClassList();
            },
        }
    }
</script>

<style scoped lang="scss">
    .class-top {
        display: flex;
        flex-wrap: wrap;
        padding:10px 20px;
        border-bottom: 1px solid #eaeaea;
        .top-left {
            width: 1%;
            flex: 1;
            .left-title {
                font-size: 14px;
                line-height: 40px;
                font-weight: 500;
            }
        }
    }
    .major-screening{
        padding:10px 0 10px 20px;
        .major-screening-title{
            font-size: 14px;
            margin-right:6px;
        }
    }
    ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
        background-color: transparent;
    }
    ::v-deep.el-table--medium td, ::v-deep.el-table--medium th{
        padding:14px 9px;
    }
</style>